<template>
  <div class="card">
    <div v-for="(item, index) in cardlist" :key="index">
      <div class="cardItem">
        <div class="order-floor">
          <p class="order-dian">
            <span>
              <img :src="findlogo(item)" alt=""/>
            </span>
            <span class="order-name">{{ item.order.storeName }}</span>
          </p>
        </div>
        <div class="order-name-div">
          <van-card
             v-for="(item, index) in item.order.orderLineList"
            :key="index"
            :num="item.skuQuantity"
            :price="item.actualPrice && item.actualPrice.toFixed(2)"
            :desc="item.desc"
            :title="item.skuResDto && item.skuResDto.name"
            :thumb="
              (item.skuResDto &&
                item.skuResDto.mediaList &&
                item.skuResDto.mediaList.length > 0 &&
                item.skuResDto.mediaList[0].url) ||
              'http://www.liby.com.cn/images/logo.png'
            "
          >
            <template #tags>
              <div class="middle">
                <van-tag
                  color="#ccc"
                  v-for="item in findTag(item).filter((k) => k.propertyType === 2)"
                  :key="item.value"
                  >{{ item.value }}</van-tag
                >
              </div>
            </template>
            <template #origin-price>
              <span>{{ findPrice(item) }}</span>
            </template>
          </van-card>
        </div>
        <div
          v-for="(item, index) in item.order.selectOrderLineList"
          :key="index"
        >
          <van-card
            :num="item.skuQuantity"
            :price="((item.actualPrice && item.actualPrice) || 0).toFixed(2)"
            :desc="item.desc"
            :title="item.skuResDto && item.skuResDto.name"
            :thumb="
              (item.skuResDto &&
                item.skuResDto.mediaList &&
                item.skuResDto.mediaList.length > 0 &&
                item.skuResDto.mediaList[0].url) ||
              'http://www.liby.com.cn/images/logo.png'
            "
          >
            <!-- <template #tag v-if="item.exchangeItem === true">
              <img src="../../../assets/img/package/gifts.png" alt="" />
            </template> -->
            <template #tags>
              <div class="middle">
                <van-tag
                  color="#ccc"
                  v-for="item in findTag(item).filter((k) => k.propertyType === 2)"
                  :key="item.value"
                  >{{ item.value }}</van-tag
                >
              </div>
            </template>
          </van-card>
        </div>
        <div class="total">
          <p class="total-details">金额明细</p>
          <van-cell
            title="商品总额"
            :value="'¥' + (item.order.originAmount || 0).toFixed(2)"
          />
          <van-cell
            title="活动优惠"
            :value="'-¥' + (item.promotionAmount || 0).toFixed(2)"
          />
          <van-cell :title="`小计(共${item.order.totalQuantity}件)`" :value="'￥'+ (item.order.actualAmount || 0).toFixed(2)" />
          <!-- <van-cell title="配送费用" value="¥0.00" /> -->
        </div>
      </div>
      <!-- <button class="card-btn" @click="market">申请售后</button> -->
    </div>
  </div>
</template>

<script>
export default {
  props: ["cardlist"],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    //店铺logo
    findlogo(item){
      return item?.order?.extData ? JSON.parse(JSON.parse(item.order.extData).logo)[0]?.thumbUrl : 'http://www.liby.com.cn/images/logo.png'
    },
    findPrice(item) {
      if (item.originPrice !== item.actualPrice ) {
        return `￥${item.originPrice.toFixed(2)}`;
      } else {
        return "";
      }
    },
    findTag(item) {
      return item?.skuResDto?.propertyValueList ?? [];
    },
    market() {
      this.$router.push("/aftermarket");
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
</style>
<style lang="less" scoped>
.card {
  padding: 0 12px;
  .cardItem:nth-of-type(n+1){
     margin-top: 4px;
  }
  .van-card {
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid rgba(242, 242, 242, 1);
  }
  /deep/.van-card__title {
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    color: #000000;
  }
  .van-card__desc {
    line-height: 20px;
    // position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    font-size: 11px !important;
    color: #aaaaaa !important;
  }

  /deep/.van-card__price {
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: #ff2731;
    font-family: "Montserrat", sans-serif;
  }

  /deep/.van-card__price-integer {
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
  }

  /deep/.van-card:not(:first-child) {
    margin: 0;
   }  
  .van-card__footer {
    width: 58px !important;
    height: 30px !important;
    font-family: "微软雅黑 Regular", "微软雅黑", sans-serif;
    font-weight: 400 !important;
    font-size: 12px !important;
    background-color: rgba(255, 255, 255, 0);
    .btn {
      color: #333333;
      border-radius: 50px;
      border: 1px solid rgba(228, 228, 228, 1);
    }
  }

  .card-btn {
    // position: relative;
    top: -63px;
    left: 258px;
    border-radius: 50px;
    border: 1px solid rgba(228, 228, 228, 1);
    color: #333333;
    font-family: "微软雅黑 Regular", "微软雅黑", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    width: 80px;
    height: 25px;
  }

  .order-floor {
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid rgba(242, 242, 242, 1);
    border-bottom: 0;
    width: 100%;
    border-radius: 8px 8px 0 0;
    padding: 3px 6px 0 3px;
    height: 49px;
    .order-dian {
      display: flex;
      padding-left: 8px;
      span {
        img {
          width: 32px;
          height: 32px;
          margin-top: -11px;
          border: 1px solid #e9e9e9;
          border-radius: 4px;
        }
      }
      .order-name {
        margin-left: 8px;
        // font-family: "微软雅黑 Regular", "微软雅黑", sans-serif;
        font-weight: 500;
        margin-top: -5px;
        font-style: normal;
        font-size: 12px;
        color: #555555;
        text-align: left;
        line-height: 20px;
      }
    }
  }

  .total-detail {
    font-family: "Arial-BoldMT", "Arial Bold", "Arial", sans-serif;
    font-size: 16px;
    font-weight: 700;
    // margin-left: 15px;
    position: relative;
  }

  .total {
    margin-top: 8px;
    border-radius: 8px;
    overflow: hidden;
    .total-details {
      margin: 0;
      height: 52px;
      border-radius: 8px 8px 0px 0px;
      background: #ffffff;
      font-size: 12px;
      font-weight: 600;
      line-height: 52px;
      text-align: left;
      color: #161823;
      padding-left: 12px;
    }
    /deep/.van-cell__title {
      font-size: 12px;
      font-weight: 400;
      color: #161823;
    }
    /deep/.van-cell__value {
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      font-family: "Montserrat", sans-serif;
    }
  }

  .order-name-div {
    border-radius: 0 0 8px 8px;
    overflow: hidden;
  }

  .van-card__price-integer,
  .van-card__price-currency {
    font-weight: 600;
    font-size: 14px;
  }
  .van-card__price-decimal {
    font-weight: 600;
    font-size: 14px;
  }
  .van-card__num {
    font-family: "Montserrat", sans-serif;
  }
  /deep/ .van-card {
    border-bottom: none;
  }

  .van-cell__value {
    span {
      font-family: "Montserrat", sans-serif;
    }
  }

  .van-image {
    border-radius: 4px;
    .van-image__img {
      border-radius: 4px;
    }
  }
}
</style>
