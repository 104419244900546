/*
 * @Autor: syq
 * @Date: 2021-07-12 16:37:32
 */

import request from "../../request/request";

// 获取前端类目树父节点集合
//订单列表
const queryMarketList = async () =>
  request({
    url: "/cartLine/list",
    method: "get",
  });
const queryMarketList1 = async () =>
  request({
    url: "/cartLine/list?mark=template",
    method: "get",
  });

const caculatePrice = async (params) =>
  request({
    url: `/cartLine/caculateOrderPromotion/cartLine`,
    method: "put",
    data: params,
  });
const caculatePrice1 = async (params) =>
  request({
    url: `/cartLine/caculateOrderPromotion/template`,
    method: "put",
    data: params,
  });
const submitorder = async (params) =>
  request({
    url: `/cartLine/createOrder`,
    method: "post",
    data: params,
  });
export { queryMarketList, caculatePrice, submitorder,caculatePrice1,queryMarketList1};
