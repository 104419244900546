var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},_vm._l((_vm.cardlist),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"cardItem"},[_c('div',{staticClass:"order-floor"},[_c('p',{staticClass:"order-dian"},[_c('span',[_c('img',{attrs:{"src":_vm.findlogo(item),"alt":""}})]),_c('span',{staticClass:"order-name"},[_vm._v(_vm._s(item.order.storeName))])])]),_c('div',{staticClass:"order-name-div"},_vm._l((item.order.orderLineList),function(item,index){return _c('van-card',{key:index,attrs:{"num":item.skuQuantity,"price":item.actualPrice && item.actualPrice.toFixed(2),"desc":item.desc,"title":item.skuResDto && item.skuResDto.name,"thumb":(item.skuResDto &&
              item.skuResDto.mediaList &&
              item.skuResDto.mediaList.length > 0 &&
              item.skuResDto.mediaList[0].url) ||
            'http://www.liby.com.cn/images/logo.png'},scopedSlots:_vm._u([{key:"tags",fn:function(){return [_c('div',{staticClass:"middle"},_vm._l((_vm.findTag(item).filter(function (k) { return k.propertyType === 2; })),function(item){return _c('van-tag',{key:item.value,attrs:{"color":"#ccc"}},[_vm._v(_vm._s(item.value))])}),1)]},proxy:true},{key:"origin-price",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.findPrice(item)))])]},proxy:true}],null,true)})}),1),_vm._l((item.order.selectOrderLineList),function(item,index){return _c('div',{key:index},[_c('van-card',{attrs:{"num":item.skuQuantity,"price":((item.actualPrice && item.actualPrice) || 0).toFixed(2),"desc":item.desc,"title":item.skuResDto && item.skuResDto.name,"thumb":(item.skuResDto &&
              item.skuResDto.mediaList &&
              item.skuResDto.mediaList.length > 0 &&
              item.skuResDto.mediaList[0].url) ||
            'http://www.liby.com.cn/images/logo.png'},scopedSlots:_vm._u([{key:"tags",fn:function(){return [_c('div',{staticClass:"middle"},_vm._l((_vm.findTag(item).filter(function (k) { return k.propertyType === 2; })),function(item){return _c('van-tag',{key:item.value,attrs:{"color":"#ccc"}},[_vm._v(_vm._s(item.value))])}),1)]},proxy:true}],null,true)})],1)}),_c('div',{staticClass:"total"},[_c('p',{staticClass:"total-details"},[_vm._v("金额明细")]),_c('van-cell',{attrs:{"title":"商品总额","value":'¥' + (item.order.originAmount || 0).toFixed(2)}}),_c('van-cell',{attrs:{"title":"活动优惠","value":'-¥' + (item.promotionAmount || 0).toFixed(2)}}),_c('van-cell',{attrs:{"title":("小计(共" + (item.order.totalQuantity) + "件)"),"value":'￥'+ (item.order.actualAmount || 0).toFixed(2)}})],1)],2)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }