<!--
 * @Autor: syq
 * @Date: 2021-07-12 19:23:20
-->
<template>
  <div id="address">
    <div
      class="coupon-border"
      v-for="(item, index) in defaultAddress"
      :key="index"
    >
      <div class="coupon-content">
        <div class="address-location">
          <img src="../../../assets/icon/address_icon@3x.png" alt="" />
        </div>
        <div class="coupon-body">
          <p class="coupon-name">{{ item.detail }}</p>
          <p class="address-person">
            <span class="coupon-consignee">{{ item.consignee }}</span>
            <span class="coupon-time">{{ item.phone }}</span>
          </p>

          <!-- <div class="coupon-btn"><button v-if="item.type == 2">默认</button></div> -->
        </div>
        <div class="coupon-btn-arrow">
          <van-icon class="address-icon" name="arrow" @click="add" />
        </div>
      </div>
    </div>
    <div class="coupon-border" v-if="!defaultAddress.length">
      <div class="coupon-content">
        <div class="coupon-body">
          <p class="choice-address">
            请选择地址 &nbsp;&nbsp;&nbsp;
            <van-icon class="address-icon" name="arrow" @click="add" />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { addressList } from "../../mineaddress/service";

export default {
  data() {
    return {
      defaultAddress: [],
      parentAddress: [],
      couponlist: [],
    };
  },
  computed: {},
  methods: {
    add() {
      this.$router.push("/address?page=placeorder");
    },
    async address() {
      const res = await addressList({ "qp-objId-eq": "1385867852069736451" });
      if (res.status === "0") {
        const dataList = res.data.items || [];
        this.couponlist = dataList;
        if (this.$route?.query?.addressId) {
          this.defaultAddress =
            (dataList?.length &&
              dataList.filter((i) => i.id === this.$route?.query?.addressId)) ||
            [];
        } else {
          this.defaultAddress =
            (dataList?.length && dataList.filter((i) => i.type === 2)) || [];
        }
      }
    },
  },
  mounted() {
    this.address();
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
</style>
<style lang="less" scoped>
#address {
  padding: 8px 12px 8px 12px;

  .coupon-border {
    background-color: #fff;
    border-radius: 8px;
    height: 74px;
    display: flex;
    align-items: center;
    .coupon-content {
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      align-items: center;
      box-sizing: border-box;
      color: #666666;
    }
    .address-location {
      margin-right: 5px;
      margin-left: 10px;
      img {
        width: 32px;
        height: 32px;
      }
    }
    .coupon-body {
      position: relative;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      flex: 1;
      padding-left: 4px;
      .choice-address {
        position: relative;
        top: 2px;
      }
      .coupon-name {
        margin: 0;
        padding: 2px 0 0 0;
        width: 226px;
        font-weight: 500;
        font-size: 14px;
        color: #1c2529;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        text-overflow: ellipsis;
        // width: 75vw;
      }
      .address-person {
        margin: 0;
        .coupon-consignee {
          font-size: 12px;
          color: #161823;
          margin-right: 12px;
        }
        .coupon-time {
          font-size: 12px;
          color: #1c2529;
          opacity: 0.5;
          font-family: "Montserrat", sans-serif;
        }
      }
      .coupon-btn {
        position: absolute;
        right: 60px;
        bottom: 15px;

        button {
          margin-right: 30px;
          width: 50px;
          height: 20px;
          font-weight: 400;
          font-style: normal;
          border-radius: 5px;
          text-align: center;
          border: 1px solid rgba(245, 108, 108, 1);
          background-color: rgba(245, 108, 108, 0);
          font-size: 12px;
          color: #f56c6c;
          line-height: 20px;
        }
      }
    }

    .coupon-btn-arrow {
      position: absolute;
      right: 20px;
      color: #b2b2b2;
      top: 40px;
    }
  }
}
</style>
